import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "../assets/styles.css";
import "../assets/global-styles.css";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

// Importing components/pages
import HomePage from "../pages/home-page";



function App() {
  useEffect(() => {
    AOS.init({
      duration: 800, // Animation duration in milliseconds
      easing: 'ease-in-out', // Animation easing
      once: true, // Animation should happen only once
    });
  }, []);
  return (
    // Main container div for the entire application
    <div style={{ height: "100%", width: "100%" }}>
      <Routes>
        <Route path="/" index element={<HomePage />} />
      </Routes>
    </div>
  );
}

export default App;
