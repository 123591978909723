import React from "react";
import WebWidget from "../components/web-widget";
import Navbar from "../components/navbar";
// import GetAlphaAccessSection from "../components/get-alpha-access-section";
// import CardTextSection from "../components/card-text-section";
// import supportTokenImage from "../assets/images/support-tokens-image.avif";
// import secureTransactionsImage from "../assets/images/secure-transactions-image.avif";
// import beginnerFriendlyImage from "../assets/images/beginner-friendly-image.avif";
// import bottomMobileImage from "../assets/images/bottom-mobile-image.avif";
// import ParallaxScroll from "../components/parallax-scroll";
import Footer from "../components/footer";
import { SparklesCore } from "../components/spotlight";
import HeroSection from "../components/hero-section/hero-section";
import TelegramApps from "../components/telegram-apps/telegram-apps";
function HomePage() {
  return (
    <>
      <div
        className="column-alignment"
        style={{ background: "#131316", width: "100%" }}
      >

        <Navbar />
        <div className="column-alignment" style={{ position: "relative", background: "transparent", marginTop: "110px" }}>
          <SparklesCore
            background="transparent"
            minSize={0.3}
            maxSize={1.5}
            particleDensity={20}
            className="particles_container w-full h-full"
            particleColor="#C3FC49"
          />
          <HeroSection />

          {/* <GetAlphaAccessSection
            headingTextTheUltimateMemeCoinPlatform={
              <>
                THE ULTIMATE <br /> MEMECOIN PLATFORM
              </>
            }
            websiteTitle="VapourEx"
            animation={true}
          /> */}
          <WebWidget />
        </div>

        <TelegramApps/>

        {/* <ParallaxScroll /> */}
        {/* <div className="column-alignment container-size">
          <div className="column-alignment welcome-to-vapourex-container">
            <span className="white-large-bold-text">Welcome to VapourEx!</span>
            <CardTextSection
              imgSrc={supportTokenImage}
              sectionHeading="Supports tokens on multiple chains and Dex"
              sectionDesc="Supports Base, Solana chain with Uniswap, Raydium support to place orders securely with best experience"
              animation="fade-right"
            />
            <CardTextSection
              imgSrc={secureTransactionsImage}
              sectionHeading="Secure Transactions"
              sectionDesc="All transactions are secure by encrypted API calls. Working closely with Uniswap community to make the transactions superfast with minimal slippage"
              imageOnRight={true}
              animation="fade-left"
            />
            <CardTextSection
              imgSrc={beginnerFriendlyImage}
              sectionHeading="Beginner friendly"
              sectionDesc="Onramp and offramp partners are in place to give you world class access to add funds and withdraw as per your choice without any restriction"
              animation="fade-right"
            />
          </div>
        </div> */}
        {/* <div className="column-alignment join-meme-coin-revolution-section container-size">
          <GetAlphaAccessSection
            headingTextJoinTheMemeCoinRevolution={
              <>
                Join the Meme Coin <br /> revolution
              </>
            }
            animation={false}
          />
        </div> */}
        {/* <div className="column-alignment bottom-image-container">
          <img
            src={bottomMobileImage}
            alt=""
            className="align-self-center"
            data-aos="fade-up"
            data-aos-duration="800"
          />
        </div> */}
        <Footer />
      </div>

    </>
  );
}

export default HomePage;
