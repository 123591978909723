import { useEffect, useState } from "react";
import vapourexLogo from "../assets/images/icon-background.png";
import hamburgerMenuIcon from "../assets/images/hamburger-menu.png";
function Navbar() {

    const [expandedClass, setExpandedClass] = useState("");

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 30) {
                setExpandedClass("full")
            } else {
                setExpandedClass("")
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <>
            <div className={`column-alignment nav-wrapper align-self-center align-items-center ${expandedClass}`}>
                <div className="row-alignment align-self-center" style={{ maxWidth: "1100px", width: "100%" }}>
                    <img src={vapourexLogo} alt="logo" className="nav-logo align-self-center" />
                    <h1 className="align-self-center header-name">VapourEx</h1>
                    <span className="fill"></span>
                    <div className="row-alignment align-items-center on_desktop" style={{ gap: "54px" }}>
                        <a href="https://blog.vapourex.com/" className="navbar-links" target="_blank" rel="noopener noreferrer">Blogs</a>
                        <a href="https://docs.vapourex.com/vapourex/developers" className="navbar-links" target="_blank" rel="noopener noreferrer">Developer docs</a>
                        <a href="https://docs.vapourex.com/vapourex/privacy-policy" className="navbar-links" target="_blank" rel="noopener noreferrer">Privacy policy</a>
                    </div>
                    <div className="on_mobile column-alignment">
                        <img src={hamburgerMenuIcon} alt="menu" />

                    </div>
                </div>
            </div>

        </>
    )
}

export default Navbar;