import { useEffect, useState } from "react";
import { List, ListItem, ListItemText } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { getData } from "../api/general-api";
import { calculatePercentage } from "../utils/calculatePercentage";
import { floorTo4DecimalPlaces } from "../utils/floorTo4DecimalPlaces";

function WebWidget() {
    const [coinsList, setCoinsList] = useState();
    const [coinLogo, setCoinLogo] = useState(null);
    const [inputValue, setInputValue] = useState(0);
    const [selectedCoin, setSelectedCoin] = useState(null);
    const [selectedCoinCurrentPrice, setSelectedCoinCurrentPrice] = useState();
    const [isOpen, setIsOpen] = useState(false); // State to manage the open/close state of the separate div
    const [estimatedTokensToReceive, setEstimatedTokensToReceive] = useState(0);
    const [selectedCoinReturns, setSelectedCoinReturns] = useState();
    const platformFees = 0.2;
    const networkFees = 0.02;

    const findNoOfTokens = (input) => {
        const platformFeeDeduction = calculatePercentage(input, platformFees);
        const udscAfterDeduction = input - platformFeeDeduction - networkFees;
        const tokens = udscAfterDeduction / selectedCoinCurrentPrice;
        setEstimatedTokensToReceive(floorTo4DecimalPlaces(tokens));
        console.log("tokens", tokens);
    };

    useEffect(() => {
        const getAllCoins = async () => {
            console.log("polling 60 seconds");
            try {
                const response = await getData("get_token");
                const coinsData = response.data;
                console.log(coinsData);
                setCoinsList(coinsData);
            } catch (error) {
                console.error("Error fetching coins:", error);
            }
        };
        getAllCoins(); // Initial call
        const intervalId = setInterval(getAllCoins, 60000); // Poll every 60 seconds
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array ensures this runs only once on mount

    const handleCoinSelect = (coin) => {
        setSelectedCoin(coin.ticker);
        setCoinLogo(coin.token_logo);
        setSelectedCoinCurrentPrice(floorTo4DecimalPlaces(coin.current_price));
        setSelectedCoinReturns(coin.return24h);
        console.log(coin.return24h);
        setIsOpen(false); // Close the separate div after selecting a coin
        if (inputValue) {
            findNoOfTokens(inputValue);
        } else {
            setEstimatedTokensToReceive(0);
        }
    };

    useEffect(() => {
        if (
            selectedCoin &&
            selectedCoinCurrentPrice &&
            inputValue !== 0 &&
            inputValue !== ""
        ) {
            findNoOfTokens(inputValue);
        }
    }, [selectedCoin, selectedCoinCurrentPrice]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen); // Toggle the state to open/close the separate div
    };

    const handleInputChange = (e) => {
        const value = e.target.value;

        // Regex to match numbers with up to 5 decimal places
        const regex = /^\d*\.?\d{0,5}$/;

        // Restrict input to numbers with up to 5 decimal places
        if (!regex.test(value)) return;

        // Handle the case when the value is "0"
        if (value === "0") {
            setInputValue("0");
            setEstimatedTokensToReceive(0);
            return;
        }

        // Handle the case when the value is empty
        if (value === "") {
            setInputValue("");
            setEstimatedTokensToReceive(0);
            return;
        }

        // Parse the value as a floating-point number
        const cleanValueNum = parseFloat(value);

        // Update the state with the new value
        setInputValue(value);
        if (selectedCoin) {
            findNoOfTokens(cleanValueNum);
        } else {
            setEstimatedTokensToReceive(0);
        }
    };

    return (
        <div className="column-alignment">
            <div className="column-alignment widget-wrapper align-self-center">
                <div className="row-alignment widget-sub-wrapper">
                    <div className="column-alignment" style={{ gap: "6px" }}>
                        <span className="text-secondary-color-text">Pay</span>
                        <input
                            // autoFocus={true}
                            type="number"
                            style={{
                                border: "none",
                                backgroundColor: "transparent",
                                outline: "none",
                                width: "100%",
                            }}
                            value={inputValue}
                            className="white-large-text"
                            onInput={handleInputChange}
                        />
                    </div>
                    <span className="fill"></span>
                    <div className="column-alignment align-self-center black-color-bordered-wrapper">
                        <span className="align-self-center white-medium-text">USDC</span>
                    </div>
                </div>

                <ArrowDownwardIcon fontSize="14" className="arrow_switch_icon" />

                <div className="row-alignment widget-sub-wrapper">
                    <div className="column-alignment" style={{ gap: "6px" }}>
                        <span className="text-secondary-color-text">Receive</span>
                        <span className="white-large-text">{estimatedTokensToReceive}</span>
                        {selectedCoin && (
                            <div className="row-alignment">
                                <span className="text-secondary-color-text">
                                    {selectedCoinReturns}%
                                </span>
                            </div>
                        )}
                    </div>
                    <span className="fill"></span>
                    <div
                        className="row-alignment align-self-center black-color-bordered-wrapper"
                        style={{ gap: "8px" }}
                    >
                        {coinLogo ? (
                            <img src={coinLogo} alt="coin logo" className="coin-logo" />
                        ) : null}
                        <span
                            className="align-self-center align-center row-alignment white-medium-text"
                            onClick={toggleDropdown}
                        >
                            {" "}
                            {selectedCoin || "Select token"}&nbsp;
                            <ExpandMoreIcon fontSize="10" />
                        </span>
                    </div>
                </div>

                {selectedCoin && (
                    <div className="row-alignment" style={{ padding: "0 24px" }}>
                        <span className="white-small-text">
                            1 {selectedCoin} = {selectedCoinCurrentPrice} USDC
                        </span>
                        <span className="fill"></span>
                        {/* <span style={{ color: "var(--theme-secondary-color)" }}>breakdown</span> */}
                    </div>
                )}
            </div>

            {isOpen && (
                <div className="dropdown-container-wrapper">
                    <div className="dropdown-bg " onClick={() => setIsOpen(false)}></div>
                    <div className="column-alignment black-color-bordered-wrapper dropdown-container">
                        <span className="dropdown-header">All tokens</span>
                        <List className="dropdown-content fill">
                            {coinsList?.map((coin, index) => (
                                <ListItem key={index} onClick={() => handleCoinSelect(coin)}>
                                    <img
                                        src={coin.token_logo}
                                        alt={`${coin.name} logo`}
                                        className="coin-logo"
                                        style={{ margin: "0 8px", width: "50px", height: "50px" }}
                                    />
                                    <ListItemText
                                        primary={coin.name}
                                        primaryTypographyProps={{ className: "coin-text" }}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </div>
            )}
        </div>
    );
}

export default WebWidget;
