import { width } from "@fortawesome/free-solid-svg-icons/fa0";
import vapourexLogo from "../assets/images/icon-background.png";

function Footer() {
    return (
        <>
            <div className="row-column-alignment footer-wrapper">
                <div className="column-alignment" style={{ gap: "8px", marginBottom: "18px" }}>
                    <div className="row-alignment" >
                        <img src={vapourexLogo} alt="logo" className="align-self-center nav-logo" />
                        <h1 className="align-self-center footer-header-name">VapourEx</h1>
                    </div>
                    <span className="footer-sub-text">A Meme coin exchange you will ever need.</span>
                </div>
                <span className="fill"></span>
                <div className="row-alignment footer-links-wrapper">
                    <div className="column-alignment">
                        <span className="footer-heading">Company</span>
                        <a href="mailto:hello@vapourex.com" className="footer-links">Support</a>
                        <span className="footer-links">Knowledge</span>
                        <span className="footer-links">Tutorials</span>
                    </div>
                    <div className="column-alignment">
                        <span className="footer-heading">Contact Us</span>
                        <a href="https://www.linkedin.com/company/vapourex/" className="footer-links" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                        <a href="https://x.com/vapour_exchange" className="footer-links" target="_blank" rel="noopener noreferrer">Twitter</a>

                        <span className="footer-links">Instagram</span>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Footer;