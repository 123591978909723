import telegramIcon from "../../assets/images/telegram.png";
import lockedIcon from "../../assets/images/locked.png";
import "./telegram-apps.css";
const TelegramApps = () => {
    return (
        <>
            <div className="column-alignment container-size align-center telegram-apps-container">
                <span className="telegram-app-title column-alignment"><i className="align-self-center">Join Our</i><b>Telegram Apps</b> </span>
                <div className="row-column-alignment align-center" style={{ width: "100%" }}>

                    <div className="column-alignment telegram-app-locked-button align-center" style={{ gap: "8px", cursor: "pointer" }} onClick={() => window.open("https://t.me/Vapourex_Bot/vap", "_blank", "noopener noreferrer")}>
                        <div className="row-alignment align-center">
                            <img src={telegramIcon} alt="telegram" style={{ marginRight: "8px" }} className="align-self-center" />
                            <span>@VapourExBot</span>
                        </div>
                    </div>


                    <div className="column-alignment telegram-app-locked-button locked align-center" style={{ gap: "8px" }} >
                        <div className="row-alignment align-center" style={{ gap: "8px" }}>
                            <img src={lockedIcon} alt="locked" /><img src={telegramIcon} alt="telegram" />
                            <span style={{ color: "var(--text-secondary-color)" }}>@Vapourex_Bot</span>
                        </div>
                    </div>
                </div>
                {/* <div className="row-alignment" style={{width: "100%"}}>
                    <span className="fill"></span>
                    <span className="coming-soon">coming soon</span>
                </div> */}
            </div>
        </>
    )
}


export default TelegramApps;