import heroSectionImage from "../../assets/images/hero-section-image.png";
import starIcon from "../../assets/images/star.png";
import telegramIcon from "../../assets/images/telegram.png";

import "./hero-section.css";
const HeroSection = () => {
    return (
        <>
            <div className="column-alignment container-size hero-section-container">
                <div className="row-column-alignment">
                    <div className="column-alignment fill hero-section-sub-container">
                        <span className="hero-section-title"><i>The Ultimate</i><br /><b>Memecoin</b> <i className="reverse">Platform</i></span>
                        <span className="hero-section-subtitle on_desktop">The Ultimate Memecoin Platform: Where humor meets blockchain, empowering creators with the funniest and most valuable digital assets.</span>

                    </div>

                    <div className="column-alignment" style={{ maxWidth: "450px" }}>
                        <img src={heroSectionImage} alt="hero-image" />
                    </div>

                </div>

                <div className="row-alignment align-center hero-action-button-wrapper">
                    <button className="accent-button row-alignment" onClick={() => window.open(" https://t.me/vapourex", "_blank", "noopener noreferrer")}><img src={telegramIcon} className="align-self-center" alt="telegram" style={{ marginRight: "6px" }} />VapourEx App</button>
                    <span className="vertical-divider"></span>
                    <div className="column-alignment">
                        <div className="row-alignment" style={{ gap: "2px" }}>
                            <img src={starIcon} alt="star" />
                            <img src={starIcon} alt="star" />
                            <img src={starIcon} alt="star" />
                            <img src={starIcon} alt="star" />
                            <img src={starIcon} alt="star" />
                        </div>
                        <span style={{ fontSize: "12px" }}>Trusted by many users</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeroSection;